// Storage
(function () {
    "use strict";
    const p = '<-:JSON';
    //
    function validateUser() {
        const currentCookie = getCookie('trac-validate');
        if (currentCookie !== sessionStorage.storedCookie) {
            sessionStorage.clear();
            sessionStorage.storedCookie = currentCookie;
        }
    }

    function getCookie(name) {
        var n = name + '=',
            ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(n) == 0) return c.substring(n.length, c.length);
        }
        return null;
    }

    function makeCookie(str) {
        var d = new Date(),
            t = d.getTime() + '.' + str,
            x;
        d.setTime(d.getTime() + (0.5 * 60 * 60 * 1000)); // allow 30 min per page before session ends
        x = 'expires=' + d.toUTCString();
        document.cookie = 'trac-validate=' + t + '; ' + x + ('; path=/');
        sessionStorage.storedCookie = getCookie('trac-validate');
    }

    function encode(val) {
        /* var v = '', l = val.length;
        for (var i = 0; i < l; i++) {
        	var s = val.charCodeAt(i).toString();
        	v += s.length + s;
        };
		return v; */
        return val;
    }

    function decode(val) {
        /* var v = '', s, c = 0, l = val.length;
        for (var i = 0; i < l; i++) {
        	if(c === 0){
        		c = parseInt(val.charAt(i));
        		if(s) v += String.fromCharCode(parseInt(s));
        		s = '';
        	} else {
        		s += val.charAt(i);
        		c--;
        	}
        };
        v += String.fromCharCode(s);
		return v; */
        return val;
    }
    window.app.storage = {
        init: function (str) {
            sessionStorage.clear();
            sessionStorage.id = str;
            makeCookie(str);
            return this;
        },
        updateCookie: function () {
            //if(!getCookie('trac-validate')) window.location.pathname = '/dotNet/logout.aspx';
            makeCookie(sessionStorage.id);
            return this;
        },
        save: function (key, val) {
            validateUser();
            if (key === 'badges') document.querySelector('#display-badge .badge-number').textContent = val.length;
            if (key === 'rank') document.querySelector('#display-text .rank').textContent = val;
            if (key === 'avatar') document.querySelector('#avatar-head').dataset.type = val;
            if (typeof val === 'string') val = encode(val);
            else val = encode(JSON.stringify(val) + p);
            if (val !== undefined || val !== null) sessionStorage[encode(key)] = val;
            return this;
        },
        get: function (key) {
            validateUser();
            var v = decode(sessionStorage[encode(key)]);
            if (v && v.search(p) !== -1) v = JSON.parse(v.replace(p, ''));
            //else v = decode(v);
            return v;
        },
        remove: function (key) {
            sessionStorage.removeItem(encode(key));
            return this;
        },
        clear: function () {
            sessionStorage.clear();
            document.cookie = 'trac-validate=;expires=' + new Date().toUTCString();
            return this;
        }
    };
    if (app.page === 'index') {
        app.storage.clear();
    } else if (app.session || app.page === 'home') {
        app.rank = Flow.getRank();
        app.badges = app.storage.get('badges') || [];
        app.storage.save('rank', app.rank).save('badges', app.badges);
        app.avatar = app.storage.get('avatar') || 'avatar-1';
    }
}());