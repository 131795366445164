// App
(function () {
    "use strict";
    let mainWrapper = document.getElementById('main-wrapper'),
        svg = document.getElementById('display'),
        h100 = 720,
        scale,
        pathArr = window.location.pathname.split('/'),
        rect, scrollLeft, scrollTop;

    window.app = {
        id: 'Project TRAC',
        isLive: false,
        isTouchDevice: false, //see components.js
        isIEorEdge: (navigator.appName == 'Microsoft Internet Explorer' || navigator.userAgent.search('Edge') !== -1 || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null))),
        noWebAudio: typeof AudioContext === "undefined" && typeof webkitAudioContext === "undefined" ? true : false,
        act: {},
        avatarView: undefined,
        avatar: undefined,
        rank: undefined,
        badges: undefined,
        session: (function () {
            return pathArr[1].search('session') !== -1 ? pathArr[1] : undefined;
        }()),
        page: (function () {
            return pathArr[pathArr.length - 1].split('.')[0];
        }()),
        stage: $('#stage'),
        ui: $('#ui'),
        holder: document.querySelector('.holder'),
        videoDomain: 'http://video.kleinbuendel.com/project_trac/',
        audioDomain: '/audio/',
        video: document.getElementById('video'),
        mainBtn: $('#main-btn'),
        continueBtn: $('<button id="continue-btn">'),
        height: undefined,
        per: undefined,
        loadAnim: undefined,
        sounds: {},
        resizeFunctions: {}, // app.resizeFunctions[myresizeFuncObj] = {func: function *required, args: array, scope: what this is}
        init: function () {
            if (app.isLive) $.ajaxSetup({
                cache: true
            });
            this.ui.find('.center').append(this.loadAnim = app.LoadAnim([255, 255, 255]));
            if (this.page === 'index' || this.page === '') {
                loginPage();
            } else {
                app.storage.updateCookie(); // update cookie on each page if prev cookie expired (30 min) user is logged out
                this.loadAnim.show();
                try {
                    $('#un-btn').text(app.storage.get('username'));
                } catch (er) { /*window.location.pathname = '/dotNet/logout.aspx';*/ }
                if (this.page === 'profile') profilePage();
                if (app.session) {
                    let index = Flow.getPageIndex(app.session, window.location.pathname + window.location.search) || Flow.getPageIndex(app.session, window.location.pathname),
                        name = Flow.getPageInfo(app.session, index).name,
                        prefix = name.substring(0, 5) === 'Stage' ? '' : `Stage ${app.session.substr(7)} - `;
                    $('title').text(`${this.id}: ${prefix}${name}`);
                }
            }
            if (this.noWebAudio) {
                createjs.FlashAudioPlugin.swfPath = "/js/vendor/";
                createjs.Sound.registerPlugins([createjs.FlashAudioPlugin, createjs.HTMLAudioPlugin]);
            } else {
                createjs.Sound.registerPlugins([createjs.WebAudioPlugin, createjs.HTMLAudioPlugin]);
            }
            let sounds = [
                {
                    id: 'mainBtn',
                    src: 'mainBtn.mp3'
                },
                {
                    id: 'mainBtnOut',
                    src: 'mainBtnOut.mp3'
                },
                {
                    id: 'meterUp',
                    src: 'meterUp_tick.mp3'
                },
                {
                    id: 'meterDown',
                    src: 'meterDown.mp3'
                },
                {
                    id: 'humm',
                    src: 'humm.mp3'
                },
                {
                    id: 'click',
                    src: 'click.mp3'
                },
                {
                    id: 'alertOn',
                    src: 'alertOn.mp3'
                },
                {
                    id: 'alertOff',
                    src: 'alertOff.mp3'
                },
                {
                    id: 'beep1',
                    src: 'beep1.mp3'
                },
                {
                    id: 'beep2',
                    src: 'beep2.mp3'
                }
            ];
            this.badgeManager.init();
            if (this.badgeManager.isBadgePage) sounds.push({
                id: 'badge',
                src: 'badge.mp3'
            });
            createjs.Sound.alternateExtensions = ["ogg"];
            createjs.Sound.registerSounds(sounds, app.audioDomain + 'universal/');
            createjs.Sound.on("fileload", onSoundLoaded, null, false, {
                count: sounds.length
            });

            function onSoundLoaded(e, d) {
                d.count -= 1;
                app.sounds[e.id] = createjs.Sound.createInstance(e.id);
                if (d.count === 0) {
                    e.remove();
                    app.sounds.humm.play({
                        volume: 0,
                        loop: -1
                    });
                    TweenLite.to(app.sounds.humm, 2, {
                        volume: 1
                    });
                    //
                    TweenLite.to('#main-wrapper', 1, {
                        opacity: 1,
                        delay: 0.2,
                        onStart: function () {
                            if (svg.getAttribute('data-type') === 'avatar') {
                                app.avatarView = app.NewComponent('div', {
                                    id: 'avatar-head',
                                    'data-type': app.avatar
                                });
                                app.ui.find('.top').append(app.avatarView);
                            }
                            onResize();
                        },
                        onComplete: function () {
                            if (app.page.substr(0, 2) === 'v_') app.video.init();
                            else if (app.page.substr(0, 2) === 'a_') app.loadJS(`/js/${app.session}/${app.page}/${app.page}.min.js`, function () {
                                app.act.init();
                            }); //
                            else if (app.page === 'home') app.loadJS('/js/modex/modex.min.js', function () {
                                app.act.init();
                            });
                        }
                    });
                }
            }
        },
        loadJS: function (js, func, args, scope) {
            $.getScript(js, function () {
                    if (!app.isLive) console.log('js loaded', js);
                    if (func) func.apply(scope || window, args || []);
                })
                .fail(function (msg) {
                    if (!app.isLive) console.log("loadJS failed: ", js, msg);
                });
        },
        getJSON: function (path, func, args, scope) {
            $.getJSON(path, function (data) {
                    if (!app.isLive) console.log("getJSON: ", data);
                    if (func) func.apply(scope || window, (args ? [data].concat[args] : [data]));
                })
                .fail(function (msg) {
                    if (!app.isLive) console.log("getJSON failed: ", msg);
                });
        },
        getPan: function (x) {
            var dist = Math.sqrt((595 - x) * (595 - x)),
                pan = x === 595 ? 0 : dist / 595;
            if (pan > 1) pan = 1;
            if (x < 595) return -pan;
            else return pan;
        },
        offset: function (el) {
            if (!this.holder) return {
                top: 360,
                left: 595
            };
            rect = el.getBoundingClientRect();
            scrollLeft = this.holder.scrollLeft;
            scrollTop = this.holder.scrollTop;
            return {
                top: rect.top + scrollTop,
                left: rect.left + (rect.width / 2) + scrollLeft
            };
        }
    };
    //
    function loginPage() {
        //console.log('loginPage()');
        $('form').submit(function (e) {
            //e.preventDefault();
            app.loadAnim.show();
            var data = {},
                un, pw;
            for (var i = 0; i < e.target.length; i++) {
                if (e.target[i].name !== '') data[e.target[i].name] = e.target[i].value;
            }
            un = data.username;
            pw = data.password;
            if (!app.isLive) console.log(data, e.target.id);
            //
            $.ajax({
                type: 'POST',
                url: e.target.id === 'login-form' ? '/dotNet/userLogin.aspx' : '/dotNet/userAccount.aspx',
                data: JSON.stringify(data),
                contentType: "application/json",
                error: function (jqXHR, status, error) {
                    if (!app.isLive) console.log(jqXHR, status, error);
                    app.ui.find('.center').append(app.Alert('<b>ERROR!<br><br>Unable to process request.</b>', null, null, true).show(0.6));
                },
                success: function (dta) {
                    if (!app.isLive) console.log(dta);
                    app.loadAnim.hide();
                    if (dta === 'Login success' || dta === 'Registration success') {
                        app.storage.init('ID.' + un).save('username', un).save('password', pw);
                        window.location.href = Flow.home;
                    } else if (dta === 'Login failed') {
                        var confirm = app.Confirm('<b>Login failed!</b><br><br>Did you forget your username or password?<br><br>Enter your email address to login with your security question.<br><br><input name="login-email" type="email"/>');
                        confirm.setBtns({
                                text: 'Get Security Question',
                                icon: 'next',
                                color: 'green'
                            }, {
                                text: 'Close'
                            })
                            .setForm('email-form', function (e) {
                                //e.preventDefault();
                                if (confirm._form.getAttribute('btn-that-submitted') === 'noBtn') {
                                    confirm._data.remove = true;
                                } else {
                                    app.loadAnim.show();
                                    var email = $('input[name="login-email"]').val();
                                    $.ajax({
                                        type: 'POST',
                                        url: '/dotNet/retrieveLogin.aspx',
                                        data: JSON.stringify({
                                            email: email
                                        }),
                                        contentType: 'application/json',
                                        dataType: 'json',
                                        error: function (obj) {
                                            app.loadAnim.hide();
                                            if (obj.status === 200) {
                                                app.ui.find('.center').append(app.Alert('<b>Could not find the email address:<br>' + email + '</b><br><br>Try again.', 'OK', 'check', true).offFunc(confirm.show, null, confirm).show(0.6));
                                            } else {
                                                confirm.remove();
                                                app.ui.find('.center').append(app.Alert('<b>An error accured!</b><br><br>Please try again later.', 'Close', 'close', true).show(0.6));
                                            }
                                        },
                                        success: function (dta) {
                                            //console.log(dta);
                                            app.loadAnim.hide();
                                            confirm.reset()
                                                .text('<b>' + ($('option[value="' + dta.question + '"').text()) + '</b><br><br><input name="login-answer" type="text"/>')
                                                .setBtns({
                                                    text: 'Submit',
                                                    icon: 'check',
                                                    color: 'green'
                                                }, {
                                                    text: 'Cancel'
                                                })
                                                .setForm('answer-form', function (e) {
                                                    //console.log('answer-form', confirm._form.getAttribute('btn-that-submitted'), $('input[name="login-answer"]').val() === dta.answer);				
                                                    if (confirm._form.getAttribute('btn-that-submitted') === 'yesBtn') {
                                                        var answer = $('input[name="login-answer"]').val();
                                                        if (answer === dta.answer) {
                                                            app.loadAnim.show();
                                                            $.ajax({
                                                                type: 'POST',
                                                                url: '/dotNet/userLogin.aspx',
                                                                data: JSON.stringify({
                                                                    username: dta.username,
                                                                    password: dta.password
                                                                }),
                                                                contentType: "application/json",
                                                                error: function (jqXHR, status, error) {
                                                                    if (!app.isLive) console.log(jqXHR, status, error);
                                                                    app.ui.find('.center').append(app.Alert('<b>ERROR!<br><br>Unable to process request.</b>', null, null, true).show(0.6));
                                                                },
                                                                success: function (msg) {
                                                                    if (msg === 'Login success') {
                                                                        app.storage.init('ID.' + un).save('username', dta.username).save('password', dta.password);
                                                                        window.location.href = Flow.home;
                                                                    } else {
                                                                        app.ui.find('.center').append(app.Alert('<b>ERROR!<br><br>Unable to process request.</b>', null, null, true).show(0.6));
                                                                    }
                                                                }
                                                            });
                                                        } else {
                                                            confirm.hide();
                                                            app.ui.find('.center').append(app.Alert('<b>Your answer does not match!<br><br>Please try again.</b>', null, null, true).offFunc(confirm.show, null, confirm).show(0.6));
                                                        }
                                                    }

                                                }).show();
                                        }
                                    });
                                }
                            });
                        //				
                        app.ui.find('.center').append(confirm.show());
                    } else if (dta === 'Username exists already') {
                        app.loadAnim.hide();
                        app.ui.find('.center').append(app.Alert('<b>Username already exists!<br><br>Please choose a different username.</b>', 'Close', 'close', true).show(0.6));
                    } else if (dta === 'Email exists already') {
                        app.loadAnim.hide();
                        app.ui.find('.center').append(app.Alert('<b>Email address is in use by another user!<br><br>Please choose a different email address.</b>', 'Close', 'close', true).show(0.6));
                    }
                }
            });
            //
            if (e.preventDefault) e.preventDefault();
            else return false;
        });
    }
    //
    function profilePage() {
        console.log('STORAGE:::', app.storage.get('data'));
        var $form = $('#profile-form');
        $.ajax({
            type: 'GET',
            url: '/dotNet/userProfile.aspx',
            data: {
                accountId: app.storage.get('data').accountId
            },
            //contentType: "application/json",
            dataType: 'json',
            error: function (jqXHR, status, error) {
                if (!app.isLive) console.log(jqXHR, status, error);
            },
            success: function (dta) {
                if (!app.isLive) console.log(dta);
                var form = $form[0];
                for (var i = form.length - 1; i >= 0; i--) {
                    if (form[i].name !== "") form[i].value = dta[form[i].name];
                }
                $('#un-btn').text(app.storage.save('username', $('input[name="username"]').val()).save('password', $('input[name="password"]').val()).get('username'));
                app.loadAnim.hide();
            }
        });
        //
        $form.submit(function (e) {
            app.loadAnim.show();
            var data = {},
                alert = app.Alert('<b>ERROR!<br><br>Unable to update your profile at this time.</b><br><br>Please try again later.', null, null, true);
            app.ui.find('.center').append(alert);
            for (var i = 0; i < e.target.length; i++) {
                if (e.target[i].name !== '') data[e.target[i].name] = e.target[i].value;
            }
            //
            $.ajax({
                type: 'POST',
                url: '/dotNet/userProfile.aspx',
                data: JSON.stringify(data),
                contentType: "application/json",
                error: function (jqXHR, status, error) {
                    if (!app.isLive) console.log(jqXHR, status, error);
                    alert.show();
                    app.loadAnim.hide();
                },
                success: function (dta) {
                    if (!app.isLive) console.log(dta);
                    app.loadAnim.hide();
                    if (dta === 'success') {
                        $('#un-btn').text(app.storage.save('username', $('input[name="username"]').val()).save('password', $('input[name="password"]').val()).get('username'));
                        alert.text('<b>Your profile has been updated!</b>').show();
                    } else {
                        if (dta === 'Email exists already') alert.text('<b>Email address is in use by another user!<br><br>Please choose a different email address.</b>');
                        alert.show();
                    }
                }
            });
            if (e.preventDefault) e.preventDefault();
            else return false;
        });
    }
    //
    function saveProgress() {
        var data = app.storage.get('data') || {};
        data.avatar = app.storage.get('avatar') || 'avatar-1';
        data.rank = app.storage.get('rank') || 'cadet';
        data.badges = app.storage.get('badges') || []; 
        data.shapematch = app.storage.get('a_shapematch') || 0;
        data.getOutData = app.storage.get('a_get_out') || {};
        data.letItOutData = app.storage.get('a_let_it_out1_wrap') || {};
        data.thinkItOutData = app.storage.get('a_think_it_out_intro') || {};
        data.shapematchrematch = app.storage.get('a_shapematch_rematch') || 0;
        data.makePlanData = app.storage.get('a_make_plan') || {};
        data.s4WrapData = app.storage.get('a_s4_wrap') || {};
        // temp
        if(app.page !== 'home') {
        	var c = Flow.getPageIndex(app.session, window.location.pathname);
        	if(data[app.session].status === 1 && data[app.session].completed < c) data[app.session].completed = c;
        	if(Flow.getNext() === Flow.home){
        		data[app.session].status = 2;
        		var num = parseInt(app.session.substr(app.session.length-1));
        		if(data['session'+(num+1)] && data['session'+(num+1)].status === 0) data['session'+(num+1)].status = 1;
        	}
        }
        app.storage.save('data', data);
        //	
        $.ajax({
            type: 'POST',
            url: '/dotNet/userProgress.aspx',
            data: JSON.stringify(data),
            contentType: 'application/json',
            //dataType: 'json',
            error: function (jqXHR, status, error) {
                if (!app.isLive) console.log(jqXHR, status, error);
                if (app.page === 'home') {
                    app.continueBtn = null;
                    app.mainBtn.on(app.RELEASE, onContinue);
                } else {
                    if (app.video && app.video.hotspot) app.video.hotspot.hide();
                    app.mainBtn.on(app.RELEASE, onReplay);
                    //
                    app.ui.find('.center').append(app.continueBtn);
                    TweenLite.fromTo(app.continueBtn, 0.4, {
                        opacity: 0,
                        scaleX: 0.85,
                        scaleY: 0.85,
                        transformOrigin: 'center center'
                    }, {
                        autoAlpha: 1,
                        scaleX: 1,
                        scaleY: 1,
                        ease: Back.easeOut
                    });
                    app.continueBtn.on(app.RELEASE, onContinue);
                }
                app.mainBtn._tl.play(0, false);
            },
            success: function (dta) {
                if (!app.isLive) console.log(dta);
                if (app.page === 'home') {
                    app.continueBtn = null;
                    app.mainBtn.on(app.RELEASE, onContinue);
                } else {
                    if (app.video && app.video.hotspot) app.video.hotspot.hide();
                    app.mainBtn.on(app.RELEASE, onReplay);
                    //
                    app.ui.find('.center').append(app.continueBtn);
                    TweenLite.fromTo(app.continueBtn, 0.4, {
                        opacity: 0,
                        scaleX: 0.85,
                        scaleY: 0.85,
                        transformOrigin: 'center center'
                    }, {
                        autoAlpha: 1,
                        scaleX: 1,
                        scaleY: 1,
                        ease: Back.easeOut
                    });
                    app.continueBtn.on(app.RELEASE, onContinue);
                }
                app.mainBtn._tl.play(0, false);
            }
        });
    }
    //
    window.addEventListener('resize', onResize);

    function onResize() {
        //console.log('window.onresize');
        app.height = svg.clientHeight;
        if (window.innerHeight < app.height) {
            scale = window.innerHeight / app.height;
            TweenLite.set(mainWrapper, {
                scaleX: scale,
                scaleY: scale,
                transformOrigin: 'top center',
                top: 0,
                position: 'absolute'
            });
            TweenLite.set('.vert-align', {
                className: '+=top-align'
            });
        } else {
            mainWrapper.style.transform = '';
            TweenLite.set('.vert-align', {
                className: '-=top-align'
            });
        }
        //
        app.per = Math.round(app.height / h100 * 1000) / 1000;
        TweenLite.set('.height-me', {
            height: app.height
        });
        TweenLite.set('.scale-me', {
            scaleX: app.per,
            scaleY: app.per
        });
        //
        for (var a in app.resizeFunctions) {
            app.resizeFunctions[a].func.apply(app.resizeFunctions[a].scope || window, app.resizeFunctions[a].args || []);
        }
    }
    TweenLite.set('.scale-me', {
        transformOrigin: 'top left'
    });
    //
    app.mainBtn._tl = new TimelineMax({
            paused: true
        })
        .call(app.mainBtn.show, [], app.mainBtn)
        .call(onMainBtnStart)
        .add(TweenLite.to(app.mainBtn, 0.3, {
            width: app.mainBtn.css('width'),
            ease: Power2.easeIn
        }))
        .add(TweenLite.to(app.mainBtn, 0.5, {
            height: app.mainBtn.css('height'),
            ease: Back.easeOut
        }))
        .call(onMainBtnReverse, null, null, '-=0.05');
    //	
    app.mainBtn._tl.oldReverse = app.mainBtn._tl.reverse;
    app.mainBtn._tl.reverse = function () {
        this.oldReverse(0, false); // make sure events fire
    };
    //
    app.mainBtn._tl.oldPlay = app.mainBtn._tl.play;
    app.mainBtn._tl.play = function () {
        this.oldPlay(0, false); // make sure events fire
    };
    app.mainBtn.hide().css({
        width: '0px',
        height: '0px'
    });
    app.mainBtn.animIn = function () {
        saveProgress();
        return this;
    };
    //app.mainBtn._tl.vars.onComplete = function(){console.dir(app.mainBtn._tl.vars)};
    function onMainBtnStart() {
        if (!app.mainBtn._tl.reversed()) app.sounds.mainBtn.play({
            volume: 0.62
        });
    }

    function onMainBtnReverse() {
        if (app.mainBtn._tl.reversed()) app.sounds.mainBtnOut.play({
            volume: 0.62
        });
    }

    function onContinue(e) {
        app.sounds.beep2.play({
            volume: 0.25
        });
        TweenLite.to(app.sounds.humm, 0.6, {
            volume: 0
        });
        TweenLite.to('#main-wrapper', 0.6, {
            opacity: 0,
            onComplete: function () {
                window.location.href = Flow.getNext();
            }
        });
        if (app.continueBtn) TweenLite.to(app.continueBtn, 0.4, {
            opacity: 0,
            scaleX: 0.85,
            scaleY: 0.85,
            ease: Back.easeIn
        });
    }

    function onReplay(e) {
        if (app.video && !app.act.id) {
            TweenLite.to(app.continueBtn, 0.4, {
                opacity: 0,
                scaleX: 0.85,
                scaleY: 0.85,
                ease: Back.easeIn,
                onComplete: app.continueBtn.detach,
                onCompleteScope: app.continueBtn
            });
            app.mainBtn._tl.vars.onReverseComplete = function () {
                app.mainBtn.hide()._tl.vars.onReverseComplete = null;
            };
            app.mainBtn.off()._tl.reverse(0, false);
            app.video.hotspot.show();
            app.video.play();
        } else {
            window.location.reload(true);
        }
    }
    //
    $(document).ready(function () {
        if (app.noWebAudio) app.loadJS('/js/vendor/flashaudioplugin.min.js', app.init, null, app);
        else app.init();
    });
}());
