// BadgeManager
(function () {
    "use strict";
    let badgesRewarded;
    const badges = {
            a_shapematch: {
                num: 1,
                name: 'Emotion Commotion Badge'
            },
            a_brainstorm: {
                num: 2,
                name: 'Word Blurb Badge'
            },
            a_myths: {
                num: 3,
                name: 'Truth Sleuth Badge'
            },
            a_label_feelings: {
                num: 4,
                name: 'Feeling Finder Badge'
            },
            a_bodytalk: {
                num: 5,
                name: 'Sensation Location Badge'
            },
            a_build_body: {
                num: 6,
                name: 'Emotion Builder Badge'
            },
            a_behavior: {
                num: 7,
                name: 'Observation Station Badge'
            },
            a_personalizing: {
                num: 8,
                name: 'Trouble Tracker Badge'
            },
            a_triggers: {
                num: 9,
                name: 'Eye Spy Badge'
            },
            a_personalizing_2: {
                num: 10,
                name: 'Trigger Trouble Badge'
            },
            a_triggers_pong: {
                num: 11,
                name: 'Trigger Me Not Badge'
            },
            a_strategies: {
                num: 12,
                name: 'Strategy Blaster Badge'
            },
            a_get_out: {
                num: 13,
                name: 'Get Out Badge'
            },
            a_let_it_out2_wrap: {
                num: 14,
                name: 'Let It Out Badge'
            },
            a_think_it_out: {
                num: 15,
                name: 'Think It Out Badge'
            },
            a_scenarios2: {
                num: 16,
                name: 'Wise Choice Badge'
            },
            a_use_strats: {
                num: 17,
                name: 'Nimble Navigator Badge'
            },
            a_shapematch_rematch: {
                num: 18,
                name: 'Distraction Reaction Badge'
            },
            a_make_plan: {
                num: 19,
                name: 'Master Planner Badge'
            }
        },
        markup = '<h2>Congratulations!</h2><p>You have completed Challenge $NUM. You have earned the:</p> <h3 class="badge-name">$BADGE</h3><img src="/images/badge.svg" width="15%">';
    //
    window.app.badgeManager = {
        init: () => {
            badgesRewarded = app.storage.get('badges') || [];
            for (let i = 0, l = badgesRewarded.length; i < l; i++) {
                badges[badgesRewarded[i]].rewarded = true;
            }
        },
        reward: (alrt, delay = 0) => {
            const alert = alrt || app.ui.find('.center').append(app.Alert().addClass('b-reward')).find('.b-reward')[0];
            const obj = badges[app.page];
            /* if not rewarded yet show pop-up, else show continue & replay buttons */
            if (obj && !obj.rewarded) {
                alert.reset().text(markup.replace('$NUM', obj.num).replace('$BADGE', obj.name)).hideFunc(app.mainBtn.animIn, null, app.mainBtn).show(delay);
                badgesRewarded.push(app.page);
                app.storage.save('badges', badgesRewarded);
                app.sounds.badge.play({
                    volume: 0.5,
                    delay: 200 + (delay * 1000)
                });
            } else {
                if (!obj) throw new Error(`BadgeManager.reward(): No badge exits for ${app.page}.`);
                TweenLite.delayedCall(delay, app.mainBtn.animIn, null, app.mainBtn);
            }
        },
        getbadgeName: str => {
            return badges[str].name;
        }
    };
    //
    Object.defineProperty(app.badgeManager, 'isBadgePage', {
        get: function () {
            return badges[app.page] ? true : false;
        },
        set: function () {
            throw new Error('BadgeManager.isBadgePage is a read only property.');
        }
    });

}());