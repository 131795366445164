// Flow
(function () {
    "use strict";
    //
    const map = {
        home: '/home.aspx',
        next: undefined,
        session1: [
            {
                path: '/session1/a_avatar.aspx',
                name: 'Choose Your Avatar'
            },
            {
                path: '/session1/a_s1_intro.aspx',
                name: 'Stage 1 Intro'
            },
            {
                path: '/session1/a_shapematch.aspx',
                name: 'Shape Matching Game',
                challenge: true
            },
            {
                path: '/session1/a_shapematch_wrap.aspx',
                name: 'Shape Matching Wrap-Up'
            },
            {
                path: '/session1/v_brainstorm_intro.aspx',
                name: 'Feelings Brainstorm Intro'
            },
            {
                path: '/session1/a_brainstorm.aspx',
                name: 'Feelings Brainstorm Game',
                challenge: true
            },
            {
                path: '/session1/a_brainstorm_wrap.aspx',
                name: 'Feelings Brainstorm Wrap-Up'
            },
            {
                path: '/session1/v_myths_intro.aspx',
                name: 'Emotion Myth Smashers Intro'
            },
            {
                path: '/session1/a_myths.aspx',
                name: 'Emotion Myth Smashers Game',
                challenge: true
            },
            {
                path: '/session1/a_myths_wrap.aspx',
                name: 'Emotion Myth Smashers Wrap-Up'
            },
            {
                path: '/session1/a_label_feelings.aspx',
                name: 'Labeling Feelings Game',
                challenge: true
            },
            {
                path: '/session1/v_bodytalk_intro.aspx',
                name: 'Slingshot Intro'
            },
            {
                path: '/session1/a_bodytalk.aspx',
                name: 'Slingshot Game',
                challenge: true
            },
            {
                path: '/session1/v_bodytalk_wrap.aspx',
                name: 'Slingshot Wrap-Up'
            },
            {
                path: '/session1/a_build_body.aspx',
                name: 'Personalizing Body Talk Game',
                challenge: true
            },
            {
                path: '/session1/v_build_body_wrap.aspx',
                name: 'Personalizing Body Talk Wrap-Up'
            },
            {
                path: '/session1/v_s1_wrap.aspx',
                name: 'Stage 1 Wrap-Up'
            }
        ],
        session2: [
            {
                path: '/session2/a_s2_intro.aspx',
                name: 'Stage 2 Intro'
            },
            {
                path: '/session2/v_behavior_intro.aspx',
                name: 'The Why Game Intro'
            },
            {
                path: '/session2/a_behavior.aspx',
                name: 'The Why Game',
                challenge: true
            },
            {
                path: '/session2/v_behavior_wrap.aspx',
                name: 'The Why Game Wrap-Up'
            },
            {
                path: '/session2/a_personalizing.aspx',
                name: 'Personalizing Feelings to Risk Game',
                challenge: true
            },
            {
                path: '/session2/v_triggers_intro.aspx',
                name: 'Triggers Cause Feelings Intro'
            },
            {
                path: '/session2/a_triggers.aspx',
                name: 'Triggers Cause Feelings Game',
                challenge: true
            },
            {
                path: '/session2/a_personalizing_2.aspx',
                name: 'Personalizing Triggers Game',
                challenge: true
            },
            {
                path: '/session2/v_triggers_pong_intro.aspx',
                name: 'Triggers, Feelings, Actions Connection Intro'
            },
            {
                path: '/session2/a_triggers_pong.aspx',
                name: 'Triggers, Feelings, Actions Connection Game',
                challenge: true
            },
            {
                path: '/session2/v_triggers_pong_wrap.aspx',
                name: 'Triggers, Feelings, Actions Connection Wrap-Up'
            },
            {
                path: '/session2/v_s2_wrap.aspx',
                name: 'Stage 2 Wrap-Up'
            }
        ],
        session3: [
            {
                path: '/session3/a_s3_intro.aspx',
                name: 'Stage 3 Intro'
            },
            {
                path: '/session3/v_strategies_intro.aspx',
                name: 'Asteroid Intro'
            },
            {
                path: '/session3/a_strategies.aspx',
                name: 'Asteroid Game',
                challenge: true
            },
            {
                path: '/session3/a_strategies_wrap.aspx',
                name: 'Asteroid Wrap-Up'
            },
            {
                path: '/session3/v_goliotio_intro.aspx',
                name: 'GOLIOTIO Intro'
            },
            {
                path: '/session3/a_get_out.aspx',
                name: 'Get Out Game',
                challenge: true
            },
            {
                path: '/session3/v_get_out_wrap.aspx',
                name: 'Get Out Wrap-Up'
            },
            {
                path: '/session3/a_let_it_out.aspx',
                name: 'Let It Out Part 1 Game',
                challenge: true
            },
            {
                path: '/session3/a_let_it_out1_wrap.aspx',
                name: 'Let It Out Part 1 Wrap-Up'
            },
            {
                path: '/session3/a_let_it_out2_intro.aspx',
                name: 'Let It Out Part 2 Intro'
            },
            {
                path: '/session3/a_let_it_out.aspx?p=2',
                name: 'Let It Out Part 2 Game',
                challenge: true
            },
            {
                path: '/session3/a_let_it_out2_wrap.aspx',
                name: 'Let It Out Part 2 Wrap-Up'
            },
            {
                path: '/session3/a_think_it_out_intro.aspx',
                name: 'Think It Out Intro'
            },
            {
                path: '/session3/a_think_it_out.aspx',
                name: 'Think It Out Game',
                challenge: true
            },
            {
                path: '/session3/a_use_strats_intro.aspx',
                name: 'Using Strategies to Calm Feelings Intro'
            },
            {
                path: '/session3/a_use_strats.aspx',
                name: 'Using Strategies Game',
                challenge: true
            },
            {
                path: '/session3/v_s3_wrap.aspx',
                name: 'Stage 3 Wrap-Up'
            }
        ],
        session4: [
            {
                path: '/session4/a_s4_intro.aspx',
                name: 'Stage 4 Intro'
            },
            {
                path: '/session4/v_scenarios_intro.aspx',
                name: 'Jeff and Maria Intro (Part 1)'
            },
            {
                path: '/session4/a_scenarios1.aspx',
                name: 'Jeff and Maria Game (Part 1)',
                challenge: true
            },
            {
                path: '/session4/v_scenarios2_intro.aspx',
                name: 'Jeff and Maria Intro (Part 2)'
            },
            {
                path: '/session4/a_scenarios2.aspx',
                name: 'Jeff and Maria Game (Part 2)',
                challenge: true
            },
            {
                path: '/session4/v_scenarios_wrap.aspx',
                name: 'Jeff and Maria Wrap-up'
            },
            {
                path: '/session4/a_shapematch2_intro.aspx',
                name: 'Shape Matching Game Rematch Intro'
            },
            {
                path: '/session4/a_shapematch_rematch.aspx',
                name: 'Shape Matching Game Rematch',
                challenge: true
            },
            {
                path: '/session4/a_make_plan.aspx',
                name: 'Emotion Regulation Plan',
                challenge: true
            },
            {
                path: '/session4/a_s4_wrap.aspx',
                name: 'Stage 4 Wrap-Up'
            }
        ]
    };
    //
    const ranks = ['cadet', 'specialist', 'captain', 'commander', 'master'];
    //
    // $('body').on('touchmove', function (e) {
    //     // e.preventDefault();
    // });
    //	
    window.Flow = {
        getNext: function () {
            if (app.session) {
                let ses = map[app.session];
                for (let i = ses.length - 1; i >= 0; i--) {
                    if (ses[i].path === (window.location.pathname + window.location.search)) {
                        if (ses[i + 1]) return ses[i + 1].path;
                        else return map.home;
                    }
                }
            } else if (app.page === 'home') {
                return map.next;
            }
        },
        setNext: function (str) {
            map.next = str;
        },
        getPageInfo: function (sessionNameOrNum, index) {
            var s = typeof sessionNameOrNum === 'string' && sessionNameOrNum.length > 1 ? sessionNameOrNum : 'session' + sessionNameOrNum;
            if (index > map[s].length - 1) return map[s][0];
            return map[s][index];
        },
        getSessionInfo: function (sessionNameOrNum) {
            return map[(typeof sessionNameOrNum === 'string' && sessionNameOrNum.length > 1 ? sessionNameOrNum : 'session' + sessionNameOrNum)];
        },
        getPageIndex: function (sessionName, pathName) {
            var arr = map[sessionName];
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].path === pathName) return i;
            }
            return undefined;
        },
        getRank: function () {
            if (!app.session) return undefined;
            const next = this.getNext();
            const n = parseInt(app.session.substr(app.session.length - 1)) - (app.session && next === map.home ? 0 : 1);
            let r = app.storage.get('rank') || ranks[n];
            //
            for (let i = 0, l = ranks.length; i < l; i++) {
                if (r === ranks[i]) {
                    if (n > i) r = ranks[n];
                }
            }
            return r;
        }
    };
    //
    Object.defineProperty(Flow, 'home', {
        get: function () {
            return map.home;
        },
        set: function () {
            throw new Error('Flow.home is a read only property.');
        }
    });
}());