// Videpo Player
if (app.video)(function (video) {
    "use strict";
    let context = video,
        mp4, webm, path,
        playBtn, pauseBtn, hotspot;

    //
    function makeControls(remove) {
        function removeCtrls() {
            hotspot.off(app.RELEASE, context.playBtnToggle);
            playBtn.remove();
            hotspot.remove();
            playBtn = hotspot = null;
        }
        //
        playBtn = $('<div class="play-btn">');
        pauseBtn = $('<div class="pause-btn" style="display:none;">');
        hotspot = $('<div class="vid-hotspot">');
        app.ui.find('.center').append(hotspot.append(playBtn.append(pauseBtn)));
        hotspot.playBtn = playBtn;
        TweenLite.set(playBtn, {
            transformOrigin: 'center center'
        });
        playBtn.isOn = true;
        playBtn.animIn = function () {
            TweenLite.to(playBtn, 0.4, {
                opacity: 1,
                scaleX: 1,
                scaleY: 1,
                ease: Back.easeOut,
                onComplete: pauseBtn.hide,
                onCompleteScope: pauseBtn,
                onStart: function () {
                    pauseBtn.show();
                    playBtn.show();
                    playBtn.isOn = true;
                }
            });
        };
        playBtn.animOut = function () {
            playBtn.isOn = false;
            TweenLite.to(playBtn, 0.4, {
                opacity: 0,
                scaleX: 0.85,
                scaleY: 0.85,
                ease: Back.easeIn,
                onComplete: (remove ? removeCtrls : playBtn.hide),
                onCompleteScope: (remove ? null : playBtn)
            });
            var t = video.currentTime,
                s = setInterval(function () {
                    if (t === video.currentTime) {
                        app.loadAnim.show();
                        clearInterval(s);
                    }
                }, 1000 / 30);
        };
    }
    //
    if (video.getAttribute('data-controls') !== 'false') makeControls();
    //
    video.hotspot = hotspot;
    video.init = function (play = true) {
        //console.log('VideoPlayer.init()');
        if (!playBtn && app.isTouchDevice) makeControls(true);
        if (!app.loadAnim) app.ui.find('.center').append(app.loadAnim = app.LoadAnim([255, 255, 255]));
        if (hotspot) {
            hotspot.on(app.RELEASE, this.playBtnToggle);
            var note = app.Note('Pause or Play:<br>Press on the video area.', 'top', 2, false).css({
                top: '25em',
                left: '24.4em'
            });
            hotspot.append(note.show());
            note.remove(true, 4);
        }
        this.addEventListener("ended", onVideoComplete, false);
        this.addEventListener("waiting", onVideoWaiting, false);
        this.addEventListener("playing", onVideoPlaying, false);
        this.addSource(app.page, play);
        return this;
    };
    video.playBtnToggle = function (e) {
        //console.log('video.playBtnToggle', context.paused || context.ended);
        if (context.paused || context.ended) {
            context.play();
            app.loadAnim.show();
            playBtn.animOut();
            if (e) app.sounds.beep2.play({
                volume: 0.1
            });
        } else {
            context.pause();
            app.loadAnim.hide();
            if (playBtn) playBtn.animIn();
            if (e) app.sounds.beep1.play({
                volume: 0.1
            });
        }
        return this;
    };
    video.addSource = function (file, play) {
        //console.log('VideoPlayer.addSource()',file);
        this.innerHTML = '';
        path = app.videoDomain + file;
        //
        if (!app.isIEorEdge) {
            webm = document.createElement('source');
            webm.src = path + '.webm';
            webm.type = "video/webm";
            this.appendChild(webm);
        }
        //
        mp4 = document.createElement('source');
        mp4.src = path + '.mp4';
        mp4.type = "video/mp4";
        this.appendChild(mp4);
        //
        this.load();
        if (play) this.play();
        else app.loadAnim.hide();
        //temp: to skip vids that don't exist yet
        /* TweenLite.delayedCall(3, function () {
            if (!app.video.duration) {
                app.mainBtn.animIn();
                app.loadAnim.hide();
            }
        }); */
        return this;
    };
    //
    function onVideoComplete() {
        //if(playBtn) playBtn.animIn();
        app.mainBtn.animIn();
    }
    //
    function onVideoPlaying() {
        //console.log('onVideoPlaying()')
        //if(buffer) requestAF(processFrame);
        if (playBtn && playBtn.isOn) playBtn.animOut();
        if ( /*!buffer && */ video.className.search('offstage') !== -1) video.className = video.className.replace('offstage', '');
        let t = video.currentTime,
            s = setInterval(function () {
                if (t < video.currentTime) {
                    app.loadAnim.hide();
                    clearInterval(s);
                }
            }, 1000 / 30);
    }
    //
    function onVideoWaiting() {
        app.loadAnim.show();
    }
    //
}(app.video));